var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "text-input-mission",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _c(
        "v-flex",
        { staticClass: "mission-instructions", attrs: { "d-flex": "" } },
        [_c("ResizableText", { attrs: { message: _vm.instructions } })],
        1
      ),
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _c("TextField", {
            staticClass: "mission-text-feild",
            attrs: {
              placeholder: _vm.placeholder,
              status: _vm.status,
              show: _vm.showTextField,
              buttonText: "SUBMIT",
            },
            on: { onSubmit: _vm.onSubmit },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }